#root > p {
  position:relative;
  top:160px;
  text-align:center;
}

[data-amplify-authenticator] {
  position:relative;
  top:160px;

  --amplify-colors-brand-primary-10: var(--amplify-colors-teal-100);
  --amplify-colors-brand-primary-80: var(--amplify-colors-teal-40);
  --amplify-colors-brand-primary-90: var(--amplify-colors-teal-20);
  --amplify-colors-brand-primary-100: var(--amplify-colors-teal-10);
}